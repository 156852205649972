import React, { useState } from 'react';
import { _defaults, _identity } from 'underscore-es';
import { CircularProgress } from '@material-ui/core';

import { useIsFeatureEnabled } from '../../../../libs/feature-toggles/react';
import Feature from '../../../../infrastructure/features';
import Alignment from './Alignment';
import { Body, Cell, Container, Footer, Header, Row } from './Table.styled';
import { ExportButton } from '../CampaignView.styled';
import { sum } from '../../../../libs/js-utils';
import ExportBtn, { ExportCampaignBtn } from './ExportBtn';

const Table = ({ columns, rows, getRowKey, exportData }) => {
  const isExportACActivityFeatureEnabled = useIsFeatureEnabled(
    Feature.EXPORT_AC_ACTIVITY
  );
  const [isExporting, setIsExporting] = useState(false);
  const [isCampaignExporting, setIsCampaignExporting] = useState(false);
  columns = columns.map(column => {
    return _defaults(column, {
      title: null,
      renderCell: _identity,
      hasFooterSeparator: false,
      alignment: Alignment.LEFT,
      span: 1,
    });
  });

  const spans = columns.map(column => column.span);
  const totalSpan = sum(spans);

  return (
    <Container totalSpan={totalSpan}>
      <Header>
        {columns.map(column => (
          <Header.Cell
            key={column.key}
            alignment={column.alignment}
            span={column.span}
          >
            {column.title}
          </Header.Cell>
        ))}
      </Header>
      <Body>
        {rows.map(row => (
          <Row key={getRowKey(row)}>
            {columns.map(column => (
              <Cell
                key={column.key}
                alignment={column.alignment}
                span={column.span}
              >
                {column.renderCell(column.getValue(row))}
              </Cell>
            ))}
            {isExportACActivityFeatureEnabled && (
              <>
                <ExportButton
                  onClick={() => {
                    setIsExporting(true);
                  }}
                >
                  <>Export Stage Activity</>
                  {isExporting && (
                    <CircularProgress size={17} color="inherit" />
                  )}
                </ExportButton>
                {isExporting && (
                  <ExportBtn
                    stageName={row.name}
                    isExporting={isExporting}
                    setIsExporting={setIsExporting}
                    stageId={getRowKey(row)}
                    exportData={exportData}
                  />
                )}
              </>
            )}
          </Row>
        ))}
      </Body>
      <Footer>
        {columns.map(column => {
          const columnValues = rows.map(row => column.getValue(row));
          return (
            <Footer.Cell
              key={column.key}
              hasSeparator={column.hasFooterSeparator}
              alignment={column.alignment}
              span={column.span}
            >
              {column.renderFooter(columnValues, rows)}
            </Footer.Cell>
          );
        })}
        {isExportACActivityFeatureEnabled && (
          <>
            <ExportButton
              onClick={() => {
                setIsCampaignExporting(true);
              }}
              style={{ marginLeft: '-15px', marginTop: '-10px' }}
            >
              <>Export All Stage Activity</>
              {isCampaignExporting && (
                <CircularProgress size={17} color="inherit" />
              )}
            </ExportButton>
            {isCampaignExporting && (
              <ExportCampaignBtn
                isCampaignExporting={isCampaignExporting}
                setIsCampaignExporting={setIsCampaignExporting}
                exportData={exportData}
              />
            )}
          </>
        )}
      </Footer>
    </Container>
  );
};

Table.Alignment = Alignment;

export default Table;
