import React from 'react';

import Campaign from '../../domain/campaign';
import OpenRate from '../../OpenRate';
import ClickRate from '../../ClickRate';
import Table from '../Table';
import { Name } from './StagesTable.styled';

const GET_ROW_KEY = row => row._id;

const COLUMNS = [
  {
    key: 'name',
    getValue: row => row.name,
    renderCell: name => <Name>{name}</Name>,
    renderFooter() {
      return <Name.Footer>Aggregate Performance</Name.Footer>;
    },
  },
  {
    key: 'openRate',
    title: 'Open Rate',
    alignment: Table.Alignment.CENTER,
    getValue: ({ analytics }) => {
      if (analytics.sent === 0) {
        return 0;
      }

      return analytics.opened / analytics.sent;
    },
    renderCell: value => <OpenRate value={value} />,
    renderFooter(_, stages) {
      return <OpenRate value={Campaign.calculateAggregateOpenRate(stages)} />;
    },
    hasFooterSeparator: true,
  },
  {
    key: 'clickRate',
    title: 'Click Rate',
    alignment: Table.Alignment.CENTER,
    getValue: ({ analytics }) => {
      if (analytics.sent === 0) {
        return 0;
      }

      return analytics.clicked / analytics.sent;
    },
    renderCell: value => <ClickRate value={value} />,
    renderFooter(_, stages) {
      return <ClickRate value={Campaign.calculateAggregateClickRate(stages)} />;
    },
    hasFooterSeparator: true,
  },
];

const StagesTable = ({ stages, exportData }) => {
  return (
    <Table
      columns={COLUMNS}
      rows={stages}
      getRowKey={GET_ROW_KEY}
      exportData={exportData}
    />
  );
};

export default StagesTable;
