import React, { useEffect, useState } from 'react';
import {
  Container,
  Group,
  HeaderButtonGroup,
  Header,
  SaveBtn,
  Title,
  Content,
  SubHeader,
  HeaderActionButton,
  ListSelect,
  ResyncButton,
} from './GroupDetails.styled';
import ResetModal from './ResetModal';
import Modal from '../../Modal';
import {
  IconButton,
  MenuItem,
  Select,
  TextField,
  InputLabel,
  FormControl,
  Checkbox,
  Tooltip,
} from '@material-ui/core';
import {
  Close,
  PersonAddDisabled,
  GroupAdd,
  ListAlt,
  CloudDone,
  HighlightOff,
} from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { groupTypesSelector } from '../../../redux/selectors/tags';
import {
  userDetailSelector,
  userPrivilegesSelector,
} from '../../../redux/selectors/user';
import { selectedOrganizationSelector } from '../../../redux/selectors/organization';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { isEmpty } from './utils';
import { createGroup, updateGroup } from '../../../redux/services/groups';
import { toast } from '../../../redux/actions/UI';
import { v4 as uuidv4 } from 'uuid';
import { makeStyles } from '@material-ui/styles';
import * as services from '../../../redux/services';
import { AmsSyncedItem } from '../../ManageAddform/amsSynced';
import { makeAMSSyncRequest } from '../../../redux/services';

const useStyles = makeStyles(() => ({
  selectLabel: {
    background: 'white',
  },
  selectField: {
    color: '#212121',
    '&:focus': {
      backgroundColor: 'transparent',
      borderColor: 'rgba(0, 0, 0, 0.23)',
    },
  },
}));

const orgTypeNameMap = {
  magic: 'Rapattoni',
  gz: 'GrowthZone',
  ramco: 'RAMCO',
  tangilla: 'Tangilla',
};

const GroupDetails = ({
  closeDrawer,
  drawerTitle,
  existingGroup,
  updateTable,
}) => {
  const [groupName, setGroupName] = useState(existingGroup?.name || '');
  const [groupDescription, setGroupDescription] = useState(
    existingGroup?.description || ''
  );
  const [groupType, setGroupType] = useState(null);
  const [isResetModalOpen, setIsResetModalOpen] = useState(false);
  const [groupNameError, setGroupNameError] = useState(false);
  const [groupDescriptionError, setGroupDescriptionError] = useState(false);
  const [orgTypeName, setOrgTypeName] = useState('');
  const [availableLists, setAvailableLists] = useState([]);
  const [filteredLists, setFilteredLists] = useState([]);
  const [selectedListID, setSelectedListID] = useState(null);
  const [loadingLists, setloadingLists] = useState(false);
  const [allTimeFilter, setAllTimeFilter] = useState(false);
  const [isListRemovalModalOpen, setIsListRemovalModalOpen] = useState(false);

  const groupTypes = useSelector(groupTypesSelector);
  const userPrivileges = useSelector(userPrivilegesSelector);
  const userDetail = useSelector(userDetailSelector);
  const selectedOrganization = useSelector(selectedOrganizationSelector);

  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();

  const formatDrawerTitle = () => {
    if (existingGroup) {
      const formattedGroupName = `${
        existingGroup.name.length > 30
          ? existingGroup.name.substring(0, 30) + '...'
          : existingGroup.name
      }`;
      return `${formattedGroupName}: ${existingGroup.subscribedMembers} Contacts`;
    } else {
      return drawerTitle;
    }
  };

  const handleNameChange = event => {
    const newValue = event.target.value;
    setGroupName(newValue);

    //If there is currently a validation error, check to see if it should be reset.
    if (newValue && groupNameError) setGroupNameError(false);
  };

  const handleDescriptionChange = event => {
    const newValue = event.target.value;
    setGroupDescription(newValue);

    if (newValue && groupDescriptionError) setGroupDescriptionError(false);
  };

  const handleTypeChange = event => {
    const newGroupTypeId = event.target.value;
    const groupTypeObject = groupTypes.find(type => type.id === newGroupTypeId);
    setGroupType(groupTypeObject);
  };

  const refresh = async () => {
    closeDrawer();
    updateTable(selectedOrganization.id);
  };

  const applyToContacts = data => {
    history.push({
      pathname: 'memberlist',
      data,
    });
  };

  const save = async () => {
    if (isEmpty(groupName) || isEmpty(groupDescription)) {
      setGroupNameError(isEmpty(groupName));
      setGroupDescriptionError(isEmpty(groupDescription));
      return;
    }

    try {
      const now = new Date();
      const basicGroupData = {
        name: groupName,
        description: groupDescription,
        groupTypeId: groupType.id,
        updatedDate: now.toString(),
      };

      const existingGroupListId =
        existingGroup?.AMS?.gz?.listId ||
        existingGroup?.AMS?.ramco?.listId ||
        '';
      const updateListId = selectedListID || ''; //Default is null and that screws up comparison so it is changed to empty string
      const hadListButRemoved = existingGroupListId && !updateListId;
      const hadListButChanged = existingGroupListId !== updateListId;
      const noListButAdded = !existingGroupListId && updateListId;

      const needsListDataUpdate =
        existingGroup && (hadListButChanged || noListButAdded);

      if (hadListButRemoved) {
        basicGroupData['AMS.synced'] = false;
        if (orgTypeName === orgTypeNameMap.gz) {
          basicGroupData['AMS.gz.listId'] = '';
          basicGroupData['AMS.gz.listName'] = '';
          basicGroupData['AMS.gz.listCategoryName'] = '';
        }
        if (orgTypeName === orgTypeNameMap.ramco) {
          basicGroupData['AMS.ramco.listId'] = '';
          basicGroupData['AMS.ramco.listName'] = '';
        }
      } else if (needsListDataUpdate) {
        const selectedListDetail = availableLists.find(
          list => list.id === selectedListID
        );
        if (!selectedListDetail) {
          dispatch(
            toast('error', 'Error updating your group, list id not found.', 5)
          );
          return;
        }

        basicGroupData['AMS.synced'] = true;
        if (orgTypeName === orgTypeNameMap.gz) {
          basicGroupData['AMS.gz.listId'] = selectedListID;
          basicGroupData['AMS.gz.listName'] = selectedListDetail.name;
          basicGroupData['AMS.gz.listCategoryName'] =
            selectedListDetail.categoryName;
        }
        if (orgTypeName === orgTypeNameMap.ramco) {
          basicGroupData['AMS.ramco.listId'] = selectedListID;
          basicGroupData['AMS.ramco.listName'] = selectedListDetail.name;
        }
      }

      //When updating an existing group:
      if (existingGroup) {
        const updateGroupData = {
          ...basicGroupData,
          id: existingGroup.id,
        };

        const updateRes = await updateGroup(updateGroupData);
        if (updateRes.error) {
          dispatch(toast('error', 'Error updating your group.', 5));
        } else {
          dispatch(toast('success', 'Successfully updated your group.', 5));

          const existingSyncedList = existingGroup.AMS?.ramco?.listId;
          if (selectedListID && selectedListID !== existingSyncedList)
            await handleGroupListResync();

          closeDrawer();
          updateTable(selectedOrganization.id);
        }
      } else {
        //When creating a new group
        const newGroupData = {
          ...basicGroupData,
          organizationId: selectedOrganization.id,
          delete: false,
          createdDate: now.toString(),
          id: uuidv4(),
          subscribedMembers: 0,
        };
        const createRes = await createGroup(newGroupData);
        if (createRes.error) {
          dispatch(toast('error', 'Error creating your group.', 5));
        } else {
          dispatch(toast('success', 'Successfully created your group.', 5));
          closeDrawer();
          updateTable(selectedOrganization.id);
        }
      }
    } catch (err) {
      console.error('Failed to save group data', err);
      dispatch(toast('error', 'Erroring saving group.', 5));
    }
  };

  const handleListSearch = search => {
    if (!search) {
      setFilteredLists(availableLists);
    } else {
      const lowerSearch = search.toLowerCase();
      const filteredResults = availableLists.filter(list => {
        if (!list?.name) return false; // Handle cases where name is missing
        // Construct the value to search
        const listValToSearch = `${list.name} ${
          list.categoryName ? `(${list.categoryName})` : ''
        } ${list.created ? `(created ${list.created})` : ''}`;

        // Perform the search match
        return listValToSearch.toLowerCase().includes(lowerSearch);
      });

      setFilteredLists(filteredResults);
    }
  };

  const getOrgTypeDisplay = async () => {
    const allOrgTypes = await services.getOrgTypes();
    const selectedOrgType = Array.isArray(allOrgTypes)
      ? allOrgTypes.find(type => type.id === selectedOrganization.orgType)
      : null;

    if (selectedOrgType) {
      const displayName = orgTypeNameMap[selectedOrgType.name]
        ? orgTypeNameMap[selectedOrgType.name]
        : 'N/A';
      setOrgTypeName(displayName);
    }
  };

  const grabLists = async () => {
    try {
      setloadingLists(true);

      let listFilter = 'type<eq>false AND createdfromcode<eq>2';

      if (!allTimeFilter) {
        const fiveYearsBack = moment()
          .subtract(5, 'years')
          .format('YYYY-MM-DD');
        listFilter += ` AND createdon<ge>${fiveYearsBack}`;
      }

      const fetchLists =
        orgTypeName === orgTypeNameMap.gz
          ? services.fetchGZLists(selectedOrganization.id, userDetail.id)
          : services.fetchRAMCOLists(selectedOrganization.id, listFilter);

      const { success, lists } = await fetchLists;

      if (success) {
        setAvailableLists(lists);
        setFilteredLists(lists);
      } else {
        dispatch(toast('error', 'Error fetching AMS lists.', 5));
      }
    } catch (error) {
      dispatch(toast('error', 'Unexpected error occurred.', 5));
    } finally {
      setloadingLists(false);
    }
  };

  const handleGroupListResync = async () => {
    try {
      const orgId = selectedOrganization.id;
      const userId = userDetail.id;
      const listId = selectedListID;
      const groupId = existingGroup.id;
      const baseEndpoint =
        orgTypeName === orgTypeNameMap.gz
          ? 'gz/list/single'
          : 'ramco/list/single';
      const endpoint = `${baseEndpoint}?orgId=${orgId}&userId=${userId}&listId=${listId}&groupId=${groupId}`;
      const syncRes = await makeAMSSyncRequest(endpoint);
      if (syncRes.success) {
        dispatch(
          toast(
            'success',
            'Starting to resync your group with the AMS list, check back soon.',
            5
          )
        );
      } else {
        dispatch(toast('error', syncRes.message, 5));
        console.error('Failed to resync group with AMS list', syncRes.message);
      }
    } catch (err) {
      dispatch(toast('error', 'Failed to resync group with AMS list.', 5));
      console.error('Failed to resync group with AMS list', err);
    }
  };

  useEffect(() => {
    getOrgTypeDisplay();

    //By default for new groups, set type to Custom.
    if (!existingGroup) {
      const customGroupType = groupTypes.find(type => type.name === 'Custom');
      setGroupType(customGroupType);
    } else {
      const selectedType = groupTypes.find(
        type => type.id === existingGroup.groupTypeId
      );
      setGroupType(selectedType);

      if (existingGroup.AMS?.synced) {
        //This allows the list dropdown to show the already selected list on drawer open
        const listId = existingGroup.AMS?.gz
          ? existingGroup.AMS?.gz?.listId
          : existingGroup.AMS?.ramco?.listId;
        const name = existingGroup.AMS?.gz
          ? existingGroup.AMS?.gz?.listName
          : existingGroup.AMS?.ramco?.listName;
        const filteredListItem = {
          id: listId,
          name: name,
        };
        if (existingGroup?.AMS?.gz?.listCategoryName) {
          // For GZ lists
          filteredListItem['categoryName'] =
            existingGroup?.AMS?.gz?.listCategoryName;
        }
        setFilteredLists([filteredListItem]);
        setSelectedListID(listId);
      }
    }
  }, [existingGroup]);
  // TODO
  //  styling - Dropdown moves after using X button to remove list
  //  Maybe change full table refresh to only happen after resetting or applying contacts to group? Annoying when it refreshes no matter what changed
  return (
    <Container>
      <Header>
        <Group>
          <IconButton onClick={closeDrawer} style={{ color: 'white' }}>
            <Close />
          </IconButton>
          <Title>{formatDrawerTitle()}</Title>
        </Group>

        <HeaderButtonGroup>
          {existingGroup && (
            <HeaderActionButton
              onClick={() => {
                applyToContacts(existingGroup);
              }}
            >
              <Tooltip title="Add contacts to Group" arrow>
                <GroupAdd fontSize={'small'} />
              </Tooltip>
            </HeaderActionButton>
          )}
          {existingGroup && (
            <HeaderActionButton
              onClick={() => {
                setIsResetModalOpen(true);
              }}
            >
              <Tooltip title="Clear all Contacts from Group" arrow>
                <PersonAddDisabled fontSize={'small'} />
              </Tooltip>
            </HeaderActionButton>
          )}

          <SaveBtn onClick={() => save()}>Save</SaveBtn>
        </HeaderButtonGroup>
      </Header>

      <Content>
        {existingGroup?.AMS?.synced && (
          <SubHeader>
            <span
              style={{ display: 'flex', alignItems: 'center', gap: '15px' }}
            >
              <AmsSyncedItem text={'AMS-synced'} color={'#007ade'} />
              <span style={{ color: 'gray', fontSize: '12px' }}>
                Last Sync:{' '}
                {moment
                  .unix(existingGroup.AMS.lastSyncTime)
                  .format('MM.DD.YY h:mma')}
              </span>
            </span>

            <ResyncButton onClick={handleGroupListResync}>
              Resync with AMS List
            </ResyncButton>
          </SubHeader>
        )}

        {userPrivileges.isSU && (
          <>
            <span
              style={{
                display: 'flex',
                marginBottom: '10px',
                alignItems: 'center',
                gap: '10px',
              }}
            >
              <span>⁂</span>
              <InputLabel style={{ color: '#444444', fontWeight: 500 }}>
                Group Type
              </InputLabel>
            </span>

            <FormControl
              variant="outlined"
              size="small"
              style={{ width: '25%' }}
            >
              <Select
                className={classes.selectField}
                style={{
                  fontSize: '14px',
                  marginBottom: '25px',
                }}
                value={groupType?.id || ''}
                defaultValue={groupType?.id || ''}
                onChange={event => handleTypeChange(event)}
                disabled={!!existingGroup}
              >
                {groupTypes.map(type => {
                  return (
                    <MenuItem value={type.id} key={type.id}>
                      {type.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </>
        )}
        <span
          style={{
            display: 'flex',
            marginBottom: '15px',
            alignItems: 'center',
            gap: '10px',
          }}
        >
          <ListAlt style={{ color: '#444444' }} />
          <InputLabel style={{ color: '#444444', fontWeight: 500 }}>
            Group Info
          </InputLabel>
        </span>
        <TextField
          label="Group Name"
          variant="outlined"
          size="small"
          style={{ width: '100%', marginBottom: '20px' }}
          value={groupName}
          onChange={e => handleNameChange(e)}
          error={groupNameError}
          helperText={groupNameError ? 'Please enter a group name.' : ''}
        />
        <TextField
          label="Group Description"
          variant="outlined"
          size="small"
          style={{ width: '100%', marginBottom: '20px' }}
          multiline={true}
          minRows={3}
          value={groupDescription}
          onChange={e => handleDescriptionChange(e)}
          error={groupDescriptionError}
          helperText={
            groupDescriptionError ? 'Please enter a group description.' : ''
          }
        />

        {existingGroup && (
          <>
            <TextField
              label="Group ID"
              variant="outlined"
              size="small"
              style={{ width: '100%', marginBottom: '20px' }}
              value={existingGroup.id}
              disabled
            />
            <TextField
              label="Created Date"
              variant="outlined"
              size="small"
              style={{ width: '100%' }}
              value={moment(existingGroup.createdDate).format(
                'MM.DD.YY hh:mma'
              )}
              disabled
            />
            {userPrivileges.isSU && (
              <TextField
                label="⁂ Last Modified Date"
                variant="outlined"
                size="small"
                style={{ width: '100%', marginTop: '20px' }}
                value={moment(existingGroup.updatedDate).format(
                  'MM.DD.YY hh:mma'
                )}
                disabled
              />
            )}
          </>
        )}

        {existingGroup &&
          [orgTypeNameMap.gz, orgTypeNameMap.ramco].includes(orgTypeName) &&
          groupType?.name === 'Custom' && ( //Currently only available to RAMCO customers
            <>
              <span
                style={{
                  display: 'flex',
                  marginTop: '35px',
                  alignItems: 'center',
                  gap: '10px',
                }}
              >
                <CloudDone style={{ color: '#444444' }} />
                <InputLabel style={{ color: '#444444', fontWeight: 500 }}>
                  AMS Connection
                </InputLabel>
              </span>
              <p style={{ color: '#444444', fontSize: '13px' }}>
                Configure AMS-synced contacts to be automatically added to this
                Group.
              </p>

              <span style={{ color: '#444444', fontSize: '15px' }}>
                {orgTypeName} List Sync
              </span>
              <p style={{ color: '#444444', fontSize: '13px' }}>
                Automatically add contacts to this Group when they belong to the
                following list in {orgTypeName}:
              </p>

              <span
                style={{ width: '100%', display: 'flex', alignItems: 'center' }}
              >
                <ListSelect
                  showSearch
                  placeholder={'Select List'}
                  onChange={value => {
                    setSelectedListID(value);
                  }}
                  onDropdownVisibleChange={e => {
                    if (e && !availableLists?.length) grabLists();
                  }}
                  value={selectedListID}
                  defaultValue={selectedListID}
                  onSearch={value => handleListSearch(value)}
                  loading={loadingLists}
                  filterOption={false}
                  dropdownRender={menu =>
                    loadingLists ? (
                      <span
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <span>*Beep boop* ... fetching lists...</span>
                      </span>
                    ) : (
                      <>{menu}</>
                    )
                  }
                  options={filteredLists.map(list => {
                    if (list?.categoryName) {
                      // For GZ lists
                      return {
                        value: list.id,
                        label: `${list.name} (${list.categoryName})`,
                      };
                    }
                    return {
                      value: list.id,
                      label: list?.created
                        ? `${list.name} (created ${list.created})`
                        : list.name,
                    };
                  })}
                />
                {selectedListID && (
                  <IconButton
                    style={{ color: '#ff6161' }}
                    onClick={() => {
                      setIsListRemovalModalOpen(true);
                    }}
                  >
                    <HighlightOff size="small" />
                  </IconButton>
                )}
              </span>

              {orgTypeName === orgTypeNameMap.ramco && (
                <span
                  style={{
                    color: '#444444',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Checkbox
                    color={'primary'}
                    checked={allTimeFilter}
                    onChange={() => {
                      setAllTimeFilter(!allTimeFilter);
                      setAvailableLists([]); //To force the dropdown to refresh
                    }}
                  />
                  <span>Show lists that are more than 5 years old</span>
                </span>
              )}
            </>
          )}
      </Content>

      {existingGroup && isListRemovalModalOpen && (
        <Modal
          title={'Remove AMS List Connection?'}
          onClose={() => setIsListRemovalModalOpen(false)}
          primaryButton={{
            label: 'Remove',
            onClick: () => {
              setSelectedListID(null);
              setIsListRemovalModalOpen(false);
            },
          }}
          secondaryButton={{
            label: 'Cancel',
            onClick: () => setIsListRemovalModalOpen(false),
          }}
        >
          Are you sure you would like to remove the list connection from this
          group? Removing the list will not remove any contacts from the group
          and will only remove the list connection.
        </Modal>
      )}

      {isResetModalOpen && existingGroup && (
        <ResetModal
          name={existingGroup?.name}
          id={existingGroup?.id}
          type={'Group'}
          closeModal={() => setIsResetModalOpen(false)}
          orgId={selectedOrganization.id}
          refresh={refresh}
        />
      )}
    </Container>
  );
};

export default GroupDetails;
