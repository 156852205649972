import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { CSVLink } from 'react-csv';
import { format } from 'date-fns';
import {
  fetchACEmailActivityByStageId,
  fetchACEmailActivityByCampaignId,
} from '../../api';
import { selectedOrganizationSelector } from '../../../../redux/selector';

export const convertToCSVFormat = (data, commonData = {}) => {
  const keys = [
    'sentDate',
    'name',
    'timestamp',
    'contactName',
    'memberId',
    'memberNRDSID',
    'email',
    'delivered',
    'clickCount',
    'openCount',
  ];

  const csvData = [
    [
      'Date',
      'Stage Name',
      'Timestamp',
      'Contact Name',
      'Contact Member ID',
      'Contact NRDS ID',
      'Contact Email',
      'Delivery Successful Y/N',
      'Number of Clicks',
      'Number of Opens',
    ],
  ];

  data.forEach(row => {
    const updatedRow = { ...row, ...commonData };
    const result = [];

    keys.forEach(key => {
      result.push(
        typeof updatedRow[key] === 'string' && updatedRow[key].includes(',')
          ? `"${updatedRow[key]}"`
          : updatedRow[key]
      );
    });

    csvData.push(result);
  });

  return csvData;
};

const ExportBtn = ({
  isExporting,
  stageId,
  stageName,
  setIsExporting,
  exportData,
}) => {
  const selectedOrg = useSelector(selectedOrganizationSelector);

  const [csvData, setCsvData] = useState(null);
  const csvInstance = useRef(null);

  useEffect(() => {
    if (
      csvData &&
      csvInstance &&
      csvInstance.current &&
      csvInstance.current.link
    ) {
      setTimeout(() => {
        csvInstance.current.link.click();
        setCsvData('');
        setIsExporting(false);
      });
    }
  }, [csvData]);

  const fetchAnalyticsData = async () => {
    const data = await fetchACEmailActivityByStageId(stageId);
    const generatedData = convertToCSVFormat(data.activities, {
      name: stageName,
      sentDate: data.sentDate,
      timestamp: data.timestamp,
    });
    setCsvData(generatedData);
  };

  useEffect(() => {
    if (isExporting) {
      fetchAnalyticsData();
    }
  }, [isExporting]);

  const formattedDate = format(new Date(), 'MM.dd.yyyy');

  return (
    <>
      {csvData?.length ? (
        <CSVLink
          data={csvData}
          filename={`${selectedOrg.acronym}-${formattedDate}-${exportData.campaignName}-${stageName}.csv`.replaceAll(
            '/',
            '.'
          )}
          ref={csvInstance}
        />
      ) : null}
    </>
  );
};

export const ExportCampaignBtn = ({
  isCampaignExporting,
  setIsCampaignExporting,
  exportData,
}) => {
  const selectedOrg = useSelector(selectedOrganizationSelector);

  const [csvData, setCsvData] = useState(null);
  const csvInstance = useRef(null);

  useEffect(() => {
    if (
      csvData &&
      csvInstance &&
      csvInstance.current &&
      csvInstance.current.link
    ) {
      setTimeout(() => {
        csvInstance.current.link.click();
        setCsvData('');
        setIsCampaignExporting(false);
      });
    }
  }, [csvData]);

  const fetchAnalyticsData = async () => {
    const data = await fetchACEmailActivityByCampaignId(exportData.campaignId);
    const generatedData = convertToCSVFormat(
      data?.map(data => {
        return {
          ...data,
          name: exportData.stageIdToStageNameMap[data?.stageId],
        };
      })
    );
    setCsvData(generatedData);
  };

  useEffect(() => {
    if (isCampaignExporting) {
      fetchAnalyticsData();
    }
  }, [isCampaignExporting]);

  const formattedDate = format(new Date(), 'MM.dd.yyyy');

  return (
    <>
      {csvData?.length ? (
        <CSVLink
          data={csvData}
          filename={`${selectedOrg.acronym}-${formattedDate}-${exportData.campaignName}.csv`.replaceAll(
            '/',
            '.'
          )}
          ref={csvInstance}
        />
      ) : null}
    </>
  );
};

export default ExportBtn;
